import React, { useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { FormGroup, FormControl, ControlLabel, Button } from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import { onError } from "../libs/errorLib";
import config from "../config";
import "./NewMemory.css";
import { API } from "aws-amplify";
import { s3Upload } from "../libs/awsLib";


export default function NewMemory() {
  const file = useRef(null);
  const history = useHistory();
  const [content, setContent] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  function validateForm() {
    return content.length > 0;
  }

  function handleFileChange(event) {
    file.current = event.target.files[0];
  }

	async function handleSubmit(event) {
		event.preventDefault();

		if (file.current && file.current.size > config.MAX_ATTACHMENT_SIZE) {
		alert(
		  `Please pick a file smaller than ${
			config.MAX_ATTACHMENT_SIZE / 1000000
		  } MB.`
		);
		return;
		}

		setIsLoading(true);

		try {
		const attachment = file.current ? await s3Upload(file.current) : null;

		await createNote({ content, attachment });
		history.push("/");
		} catch (e) {
		onError(e);
		setIsLoading(false);
		}
	}

	function createNote(note) {
	  return API.post("saveAppNotes", "/saveAppNotes", {
		body: note
	  });
	}
	
	function showPosition() {
        if(navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(function(position) {
                var positionInfo = `Your current position is (Latitude: ${position.coords.latitude} , Longitude: ${position.coords.longitude} )`;
				var currentContent = content + "\n" + positionInfo;				
                setContent(currentContent);
            });
        } else {
            alert("Sorry, your browser does not support HTML5 geolocation.");
        }
    }

  return (
    <div className="NewMemory">
	
      <form onSubmit={handleSubmit}>
        <FormGroup controlId="content">
          <FormControl
            value={content}
            componentClass="textarea"
            onChange={e => setContent(e.target.value)}
          />
        </FormGroup>
		<Button 
		  bsSize="large"
		  bsStyle="primary"
		  onClick={showPosition}
		  >
		  Click me to add your GPS location to your memory
		  </Button>
        <FormGroup controlId="file">
          <ControlLabel>Attachment</ControlLabel>
          <FormControl onChange={handleFileChange} type="file" />
        </FormGroup>
        <LoaderButton
          block
          type="submit"
          bsSize="large"
          bsStyle="primary"
          isLoading={isLoading}
          disabled={!validateForm()}
        >
          Create
        </LoaderButton>
      </form>
	  
    </div>
  );
}